import { template as template_370657c3f9eb490d9c32221e01ced229 } from "@ember/template-compiler";
const WelcomeHeader = template_370657c3f9eb490d9c32221e01ced229(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
