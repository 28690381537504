import { template as template_589568f872954c0dac1be7d9fd36df37 } from "@ember/template-compiler";
const FKLabel = template_589568f872954c0dac1be7d9fd36df37(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
